<template>
  <div>
    <CaHeader :heading="getHsOrderType(hsOrderTypes[this.$route.params.type].api)" />
    <div class="container-fluid px-4 pt-4">
      <DataTable
        :fields="fields"
        service="hs-order"
        :base-query="baseQuery"
        :search-field="false"
        @row-clicked="(item) => $router.push({name: 'HSSOrderDetail', params: {orderId: item._id}})"
      >
        <template #customSearch>
          <div class="row align-items-center">
            <div class="col-auto font-weight-bold">
              Suche:
            </div>
            <BasicInput
              v-model="searchCustomerName"
              placeholder="Kundenname"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicInput
              v-model="searchPlz"
              placeholder="PLZ"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicInput
              v-model="searchKennzeichen"
              placeholder="Kennzeichen"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicInput
              v-model="searchNumber"
              placeholder="Vertragsnummer"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicSelect
              v-model="searchStatus"
              placeholder="Status"
              :margin="false"
              type="search"
              class="col"
              :options="statusOptions"
            />
            <BasicDatepicker
              v-model="searchDateFrom"
              placeholder="Datum von"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicDatepicker
              v-model="searchDateTo"
              placeholder="Datum bis"
              :margin="false"
              type="search"
              class="col"
            />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable.vue'
import formatDate from '@/filters/formatDate'
import hsOrderTypes from '@/resources/enums/hsOrderTypes.js'
import BasicInput from '@/components/BaseComponents/BasicInput'
import moment from 'moment'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import hsOrderStatus from '@/resources/enums/hsOrderStatus'
export default {
  components: {
    DataTable,
    CaHeader,
    BasicInput,
    BasicDatepicker,
    BasicSelect
  },
  data: () => ({
    hsOrderTypes,
    searchCustomerName: '',
    searchPlz: '',
    searchKennzeichen: '',
    searchNumber: '',
    searchStatus: undefined,
    searchDateFrom: '',
    searchDateTo: ''
  }),
  computed: {
    statusOptions () {
      return Object.values(hsOrderStatus).map(type => ({
        label: type.human,
        value: type.api
      }))
    },
    fields () {
      return [
        {
          label: 'Kundenname',
          key: 'customer.name',
          formatter: name => `${name.NAM} ${name.VOR ? name.VOR : ''}`,
          sortable: true
        },
        {
          label: 'Kundenanschrift',
          key: 'customer.address',
          formatter: address => `${address.STR}, ${address.PLZ} ${address.ORT}`,
          sortable: true
        },
        {
          label: 'Vertragsnummer',
          key: 'number',
          sortable: true
        },
        {
          label: 'Kennzeichen',
          key: 'carData.kennzeichen',
          sortable: true
        },
        {
          label: 'Beginn',
          key: 'versicherungsbeginn',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        },
        {
          label: 'Ablauf',
          key: 'versicherungsablauf',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        },
        {
          label: 'Status',
          key: 'hsOrderStatus',
          sortable: true
        },
        {
          label: 'Letzte Änderung am',
          key: 'aenderungGueltigAb',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        }
      ]
    },
    baseQuery () {
      const query = {
        hsOrderType: hsOrderTypes[this.$route.params.type].api
      }
      if (this.searchKennzeichen) {
        query['carData.kennzeichen'] = { $regex: this.searchKennzeichen, $options: 'i' }
      }
      if (this.searchNumber) {
        query.number = { $regex: this.searchNumber, $options: 'i' }
      }
      if (this.searchCustomerName) {
        query.customerName = this.searchCustomerName // In der api wird per hook 'searchCustomerName' die customerId ermittelt
      }
      if (this.searchPlz) {
        query.customerPLZ = this.searchPlz
      }
      if (this.searchStatus) {
        query.hsOrderStatus = { $regex: this.searchStatus, $options: 'i' }
      }
      if (this.searchDateFrom || this.searchDateTo) {
        let oldest = this.searchDateFrom ? this.searchDateFrom : this.searchDateTo
        let newest = this.searchDateTo ? this.searchDateTo : this.searchDateFrom
        if (moment(this.searchDateTo).isBefore(moment(this.searchDateFrom))) {
          oldest = this.searchDateTo ? this.searchDateTo : this.searchDateFrom
          newest = this.searchDateFrom ? this.searchDateFrom : this.searchDateTo
        }
        newest = moment(newest).add(1, 'd').toDate()

        query.aenderungGueltigAb = { $gte: oldest, $lte: newest }
      }
      return query
    }
  },
  methods: {
    getHsOrderType (curHsOrderType) {
      if (!curHsOrderType && curHsOrderType !== 0) return
      switch (curHsOrderType) {
      case hsOrderTypes.ANFRAGE.api:
        return 'Anfragen'
      case hsOrderTypes.ANGEBOT.api:
        return 'Angebote'
      case hsOrderTypes.ANTRAG.api:
        return 'Anträge'
      case hsOrderTypes.VERTRAG.api:
        return 'Verträge'
      default:
        return 'Error'
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
